<template>
  <div>
    <v-row class="elevation-form mt-5 px-3 py-3">
      <v-col cols="12">
        <h3>Options</h3>
      </v-col>
      <v-col cols="6" class="form-component">
        <label class="d-flex">Discount Type</label>
        <v-select
          :items="$parent.discountTypes"
          item-text="title"
          item-value="value"
          v-model="$parent.discount.discount_value_type"
        ></v-select>
      </v-col>
      <v-col
        v-if="
          $parent.discount.discount_value_type == 'percentage' || $parent.discount.discount_value_type == 'fixed_amount'
        "
        cols="6"
        class="form-component"
      >
        <label class="d-flex">Discount value</label>
        <v-text-field
          @input="$parent.$v.discount.discount_value.$touch"
          :error-messages="
            $parent.$v.discount.discount_value.$dirty && !$parent.$v.discount.discount_value.required
              ? $t('Discount value is required')
              : ''
          "
          v-model="$parent.discount.discount_value"
          :suffix="$parent.discount.discount_value_type == 'percentage' ? '%' : ''"
          :prefix="$parent.discount.discount_value_type == 'fixed_amount' ? currency.currencyCode() : ''"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import currency from '@/helpers/currency';

export default {
  data: function() {
    return {
      currency,
    };
  },
};
</script>
