<template>
  <v-row no-gutters class="elevation-form">
    <v-col cols="12" class="px-3 py-3">
      <h3>Summary</h3>
      <h4 class="mt-3">{{ $parent.discount.discount_code }}</h4>
      <ul class="mt-3">
        <li v-if="$parent.discount.discount_value_type == 'percentage'">
          {{ $parent.discount.discount_value }}% off entire order
        </li>
        <li v-else>{{ currency.priceFormat($parent.discount.discount_value) }} off entire order</li>
        <li v-if="$parent.discount.once_per_customer">Once per customer</li>
        <li v-if="$parent.discount.usage_limit > 0">
          Limit of {{ $parent.discount.usage_limit }} uses, once per customer
        </li>
        <li v-if="$parent.discount.discount_start_date">
          Active from {{ datetime.format($parent.discount.discount_start_date, 'DD-MM-YYYY') }}
        </li>
        <li v-if="$parent.discount.discount_end_date">
          Active to {{ datetime.format($parent.discount.discount_end_date, 'DD-MM-YYYY') }}
        </li>
      </ul>
    </v-col>
    <v-col cols="12"></v-col>
  </v-row>
</template>
<script>
import datetime from '@/helpers/datetime';
import currency from '@/helpers/currency';

export default {
  data() {
    return {
      datetime,
      currency,
    };
  },
};
</script>
