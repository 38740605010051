<template>
  <v-row class="elevation-form mt-5 px-3 py-3">
    <v-col cols="12">
      <h3>Customer eligibility</h3>
    </v-col>
    <v-col cols="12">
      <v-radio-group v-model="$parent.discount.customer_selection" class="mt-0">
        <v-radio label="Everyone" value="all"></v-radio>
        <v-radio label="Specific customer" value="prerequisite"></v-radio>
      </v-radio-group>
      <div v-if="$parent.discount.customer_selection == 'prerequisite'">
        <v-autocomplete
          v-model="selected"
          @change="onChange"
          placeholder="Search customers"
          :search-input.sync="search"
          :loading="isLoading"
          :items="customers"
          item-text="email"
          item-value="id"
          label=""
          multiple
          outlined
          dense
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item.email }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text caption"> (+{{ selected.length - 1 }} others) </span>
          </template>
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  No results matching " <strong>{{ search }}</strong> ".Press <kbd>enter</kbd> to create a new one
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { customerService } from '@/apis/customer';

export default {
  data() {
    return {
      selected: null,
      customers: [],
      search: null,
      isLoading: false,
      error: null,
    };
  },
  created() {
    this.loadCustomers();
    if (this.$parent.discount.prerequisite_customer_ids) {
      this.selected = this.$parent.discount.prerequisite_customer_ids;
    }
  },
  methods: {
    onChange() {
      this.$parent.discount.prerequisite_customer_ids = this.selected;
    },
    onInput() {
      console.log(this.search);
    },
    async loadCustomers() {
      this.isLoading = true;
      try {
        let params = {
          search: this.search,
        };
        let customerData = await customerService.list(params);
        this.customers = customerData.data.customer;
        this.isLoading = false;
      } catch (error) {
        this.error = error;
        this.isLoading = false;
      }
    },
  },
  watch: {
    search() {
      this.loadCustomers();
    },
  },
};
</script>
