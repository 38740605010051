<template>
  <div class="discount__code">
    <v-row class="elevation-form px-3 py-3">
      <v-col cols="12" class="d-flex align-center">
        <h3>Discount code</h3>
        <v-btn v-if="!$parent.discount.id" @click="generateCode" class="ml-auto btn-link" color="">Generate code</v-btn>
      </v-col>
      <v-col cols="12" class="form-component">
        <label for="">Code</label>
        <v-text-field
          @input="$parent.$v.discount.discount_code.$touch"
          :error-messages="
            $parent.$v.discount.discount_code.$dirty && !$parent.$v.discount.discount_code.required
              ? $t('Discount code is required')
              : ''
          "
          v-model="$parent.discount.discount_code"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  methods: {
    generateCode() {
      this.$parent.discount.discount_code = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < 15; i++) {
        this.$parent.discount.discount_code += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
    },
  },
  created() {},
};
</script>
