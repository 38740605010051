<template>
  <v-row class="elevation-form mt-5 px-3 py-3">
    <v-col cols="12">
      <h3>Active dates</h3>
    </v-col>
    <v-col cols="12">
      <h4>Start date</h4>
      <datetimepicker
        value-type="YYYY-MM-DD HH:mm:ss"
        format="YYYY-MM-DD HH:mm:ss"
        v-model="$parent.discount.discount_start_date"
        type="date"
      />
      <v-checkbox
        @change="onChangeSetEndDate"
        v-model="$parent.setEndDate"
        hide-details
        label="Set end date"
      ></v-checkbox>
      <h4 class="mt-5" v-if="$parent.setEndDate">End date</h4>
      <datetimepicker
        v-if="$parent.setEndDate"
        value-type="YYYY-MM-DD HH:mm:ss"
        format="YYYY-MM-DD HH:mm:ss"
        class=""
        :disabled-date="notBeforeToday"
        v-model="$parent.discount.discount_end_date"
        type="date"
      />
    </v-col>
  </v-row>
</template>
<script>
import moment from 'moment';

export default {
  data() {
    return {
      disabledDates: {
        to: new Date(Date.now()),
      },
    };
  },
  methods: {
    onChangeSetEndDate() {
      if (!this.$parent.setEndDate) {
        this.$parent.discount.discount_end_date = '';
      } else {
        this.$parent.discount.discount_end_date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
      }
    },
    notBeforeToday(date) {
      return date < new Date(new Date(this.$parent.discount.discount_start_date).setHours(0, 0, 0, 0));
    },
  },
};
</script>
