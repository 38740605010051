<template>
  <v-row v-if="discountOption">
    <v-col cols="12">
      <v-row v-for="option in discountOption.options" :key="option.title" class="elevation-form mt-5 px-3 py-3">
        <v-col cols="12">
          <h3>{{ option.title }}</h3>
        </v-col>
        <v-col cols="12">
          <v-radio-group
            hide-details
            v-for="op in option.values"
            :key="op.name"
            class="mt-0"
            @change="onChangeOption"
            v-model="$parent.discount.target_selection"
          >
            <v-radio :label="op.name" :value="op.value"></v-radio>
            <div
              class="mt-2"
              v-if="$parent.discount.target_selection == 'specific_collections' && op.value == 'specific_collections'"
            >
              <v-autocomplete
                v-model="$parent.discount.entitled_collection_ids"
                @change="loadCollectionsByIds"
                placeholder="Search collections"
                :search-input.sync="search"
                :loading="isLoading"
                :items="collections"
                item-text="title"
                item-value="_id"
                label=""
                multiple
                outlined
                dense
              >
                <template v-slot:selection="{ item, index }">
                  <!-- <v-chip v-if="index === 0">
                    <span>{{ item.title }}</span>
                  </v-chip> -->
                  <span v-if="index === 0 && item" class="grey--text caption"> Search collections </span>
                </template>
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        No results matching " <strong>{{ search }}</strong> ".Press <kbd>enter</kbd> to create a new one
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
              <v-row v-if="entitledCollections.length > 0">
                <v-col cols="12" class="py-0 m-0 list-item">
                  <v-list class="">
                    <v-list-item-group>
                      <v-list-item v-for="(item, i) in entitledCollections" :key="i">
                        <v-row>
                          <v-col cols="1" class="px-0 py-0 m-0">
                            <v-img width="40" v-if="item.image" :src="image.url(item.image.src)"></v-img>
                            <v-img width="40" v-else :src="'@/assets/images/no-image.png'"></v-img>
                          </v-col>
                          <v-col cols="9" class="px-0 py-0 m-0 d-flex align-center">
                            <span>{{ item.title }}</span>
                          </v-col>
                          <v-col cols="2" class="px-0 py-0 m-0 text-right">
                            <v-btn @click="removeCollection(item._id)" class="btn-link">
                              <v-icon small>fas fa-times</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
            </div>
            <div
              class="mt-2"
              v-if="$parent.discount.target_selection == 'specific_countries' && op.value == 'specific_countries'"
            >
              <v-autocomplete
                v-model="$parent.discount.entitled_country_ids"
                @change="loadCountriesByIds"
                placeholder="Search countries"
                :search-input.sync="search"
                :loading="isLoading"
                :items="countries"
                item-text="name"
                item-value="code"
                label=""
                multiple
                outlined
                dense
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.name }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ $parent.discount.entitled_country_ids.length - 1 }} others)
                  </span>
                </template>
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        No results matching " <strong>{{ search }}</strong> ".Press <kbd>enter</kbd> to create a new one
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
              <v-row v-if="entitledCountries.length > 0">
                <v-col cols="12" class="py-0 m-0 list-item">
                  <v-list class="">
                    <v-list-item-group>
                      <v-list-item v-for="(item, i) in entitledCountries" :key="i">
                        <v-row>
                          <v-col cols="10" class="px-0 py-0 m-0 d-flex align-center">
                            <span>{{ item.name }}</span>
                          </v-col>
                          <v-col cols="2" class="px-0 py-0 m-0 text-right">
                            <v-btn @click="removeCountry(item.code)" class="btn-link">
                              <v-icon small>fas fa-times</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
            </div>
            <div
              class="mt-2"
              v-if="$parent.discount.target_selection == 'specific_products' && op.value == 'specific_products'"
            >
              <v-autocomplete
                v-model="$parent.discount.entitled_product_ids"
                @change="loadProductsByIds"
                placeholder="Search products"
                :search-input.sync="search"
                :loading="isLoading"
                :items="products"
                item-text="title"
                item-value="_id"
                label=""
                multiple
                outlined
                dense
              >
                <template v-slot:selection="{ item, index }">
                  <!-- <v-chip v-if="index === 0">
                    <span>{{ item.title }}</span>
                  </v-chip> -->
                  <span v-if="index === 0 && item" class="grey--text caption"> Search products </span>
                </template>
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        No results matching " <strong>{{ search }}</strong> ".Press <kbd>enter</kbd> to create a new one
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
              <v-row v-if="entitledProducts.length > 0">
                <v-col cols="12" class="py-0 m-0 list-item">
                  <v-list class="">
                    <v-list-item-group>
                      <v-list-item v-for="(item, i) in entitledProducts" :key="i">
                        <v-row>
                          <v-col cols="1" class="px-0 py-0 m-0">
                            <v-img
                              width="40"
                              v-if="item.images && item.images.length > 0"
                              :src="image.url(item.images[0].src)"
                            ></v-img>
                            <v-img width="40" v-else :src="'@/assets/images/no-image.png'"></v-img>
                          </v-col>
                          <v-col cols="9" class="px-0 py-0 m-0 d-flex align-center">
                            <span>{{ item.title }}</span>
                          </v-col>
                          <v-col cols="2" class="px-0 py-0 m-0 text-right">
                            <v-btn @click="removeProduct(item._id)" class="btn-link">
                              <v-icon small>fas fa-times</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
            </div>
          </v-radio-group>
          <!-- <div v-if="$parent.discount.target_selection != 'all'">
            <v-divider class="my-5"></v-divider>
            <v-checkbox hide-details label="Only apply discount once per order"></v-checkbox>
            <small>If unchecked, this discount will apply to each eligible product in an order.</small>
          </div> -->
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { productApi } from '@/apis/product';
import { collectionApi } from '@/apis/collection';
import { shippingService } from '@/apis/shipping';
import image from '@/helpers/image';
import { imageFake } from '@/const/image';

export default {
  data() {
    return {
      selected: [],
      items: [],
      discountOptions: [
        {
          type: 'percentage',
          options: [
            {
              title: 'Applies to',
              values: [
                {
                  name: 'Entire order',
                  value: 'all',
                },
                {
                  name: 'Specific collections',
                  value: 'specific_collections',
                },
                {
                  name: 'Specific products',
                  value: 'specific_products',
                },
              ],
            },
          ],
        },
        {
          type: 'fixed_amount',
          options: [
            {
              title: 'Applies to',
              values: [
                {
                  name: 'Entire order',
                  value: 'all',
                },
                {
                  name: 'Specific collections',
                  value: 'specific_collections',
                },
                {
                  name: 'Specific products',
                  value: 'specific_products',
                },
              ],
            },
          ],
        },
        {
          type: 'free_shipping',
          options: [
            {
              title: 'Countries',
              values: [
                {
                  name: 'All countries',
                  value: 'all',
                },
                {
                  name: 'Specific countries',
                  value: 'specific_countries',
                },
              ],
            },
          ],
        },
      ],
      isLoading: false,
      type: null,
      search: null,
      products: [],
      countries: [],
      collections: [],
      entitledCollections: [],
      entitledProducts: [],
      entitledCountries: [],
      image,
      imageFake,
    };
  },
  async created() {
    await this.loadCountries();
    this.loadData();
    this.loadCollectionsByIds();
    this.loadProductsByIds();
    this.loadCountriesByIds();
  },
  methods: {
    removeCountry(code) {
      let indexEntitledCountries = this.entitledCountries.findIndex(c => c.code === code);
      let indexEntitled = this.$parent.discount.entitled_country_ids.findIndex(c => c === code);
      if (indexEntitledCountries > -1) {
        this.entitledCountries.splice(indexEntitledCountries, 1);
      }
      if (indexEntitled > -1) {
        this.$parent.discount.entitled_country_ids.splice(indexEntitled, 1);
      }
    },
    removeProduct(id) {
      let indexEntitledProducts = this.entitledProducts.findIndex(c => c._id === id);
      let indexEntitled = this.$parent.discount.entitled_product_ids.findIndex(c => c === id);
      if (indexEntitledProducts > -1) {
        this.entitledProducts.splice(indexEntitledProducts, 1);
      }
      if (indexEntitled > -1) {
        this.$parent.discount.entitled_product_ids.splice(indexEntitled, 1);
      }
    },
    removeCollection(id) {
      let indexEntitledCollections = this.entitledCollections.findIndex(c => c._id === id);
      let indexEntitled = this.$parent.discount.entitled_collection_ids.findIndex(c => c === id);
      if (indexEntitledCollections > -1) {
        this.entitledCollections.splice(indexEntitledCollections, 1);
      }
      if (indexEntitled > -1) {
        this.$parent.discount.entitled_collection_ids.splice(indexEntitled, 1);
      }
    },
    async loadCountriesByIds() {
      this.entitledCountries = this.countries.filter(c =>
        this.$parent.discount.entitled_country_ids
          ? this.$parent.discount.entitled_country_ids.includes(c.code)
          : false,
      );
    },
    async loadCollectionsByIds() {
      let ids =
        this.$parent.discount.entitled_collection_ids && this.$parent.discount.entitled_collection_ids.length > 0
          ? this.$parent.discount.entitled_collection_ids.join(',')
          : null;
      if (ids) {
        try {
          let params = {
            ids: ids,
          };
          let data = await collectionApi.get(params);
          this.entitledCollections = data.data.collections;
        } catch (error) {
          console.log(error);
        }
      }
    },
    async loadProductsByIds() {
      let ids =
        this.$parent.discount.entitled_product_ids && this.$parent.discount.entitled_product_ids.length > 0
          ? this.$parent.discount.entitled_product_ids.join(',')
          : null;
      if (ids) {
        try {
          let params = {
            ids: ids,
          };
          let data = await productApi.get(params);
          this.entitledProducts = data.data.products;
        } catch (error) {
          console.log(error);
        }
      }
    },
    async loadCountries() {
      try {
        let countriesData = await shippingService.getListCountries();
        this.countries = countriesData.data;
      } catch (error) {
        console.log(error);
      }
    },
    onChangeOption() {
      if (this.$parent.discount.target_selection === 'specific_products') {
        this.$parent.discount.entitled_collection_ids = [];
      } else if (this.$parent.discount.target_selection === 'specific_collections') {
        this.$parent.discount.entitled_product_ids = [];
      } else {
        this.$parent.discount.entitled_collection_ids = [];
        this.$parent.discount.entitled_product_ids = [];
      }
    },
    onChange() {
      if (this.$parent.discount.target_selection == 'specific_products') {
        this.$parent.discount.entitled_product_ids = this.selected;
      }
      if (this.$parent.discount.target_selection == 'specific_collections') {
        this.$parent.discount.entitled_collection_ids = this.selected;
      } else {
        this.$parent.discount.entitled_product_ids = this.selected;
      }
    },
    async searchData() {
      this.isLoading = true;
      try {
        let params = {};
        params.search = this.search;
        params.limit = 20;
        if (this.$parent.discount.target_selection == 'specific_products') {
          let productData = await productApi.get(params);
          this.products = productData.data.products;
        } else if (this.$parent.discount.target_selection == 'specific_collections') {
          let collectionData = await collectionApi.get(params);
          this.collections = collectionData.data.collections;
        }
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    async loadData() {
      this.isLoading = true;
      try {
        let params = {};
        params.search = this.search;
        params.limit = 20;
        let productData = await productApi.get(params);
        this.products = productData.data.products;
        let collectionData = await collectionApi.get(params);
        this.collections = collectionData.data.collections;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
  watch: {
    search() {
      this.searchData();
    },
  },
  computed: {
    discountOption: function() {
      return this.discountOptions.find(discount => discount.type == this.$parent.discount.discount_value_type);
    },
  },
};
</script>
<style lang="scss">
.list-item {
  max-height: 400px;
  overflow-y: auto;
}
</style>
