import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);
let path = '/discount/';

function list(params) {
  return storeRequest.get(path, { params: params });
}

function get(id) {
  return storeRequest.get(path + id);
}

function create(discount) {
  return storeRequest.post(path, discount);
}

function update(discount) {
  return storeRequest.put(path, discount);
}

function deleteById(id) {
  return storeRequest.delete(path + id);
}

function deleteByIds(ids) {
  return storeRequest.delete(path + '?ids=' + ids);
}

function checkExistsAutoActive() {
  return storeRequest.get(path, { params: { discount_status: 'active', discount_type: 'automatic' } });
}

export const discountService = {
  list,
  create,
  update,
  get,
  deleteById,
  checkExistsAutoActive,
  deleteByIds,
};
