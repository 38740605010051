<template>
  <v-row class="elevation-form mt-5 px-3 py-3">
    <v-col cols="12">
      <h3>Minimum requirement</h3>
    </v-col>
    <v-col cols="12">
      <v-radio-group
        @change="onChange"
        hide-details
        v-for="mini in minimums"
        :key="mini.name"
        class="mt-0"
        v-model="minimum"
      >
        <v-radio :label="mini.name" :value="mini.value"></v-radio>
        <v-col v-if="mini.value == 'purchase_amount' && minimum == 'purchase_amount'" class="px-0 py-0" cols="6">
          <v-text-field
            v-model="$parent.discount.prerequisite_subtotal_range.greater_than_or_equal_to"
            :prefix="minimum == 'purchase_amount' ? currency.currencyCode() : ''"
            label=""
          ></v-text-field>
          <small v-if="$parent.discount.entitled_country_ids && $parent.discount.entitled_country_ids.length"
            >Applies only to selected countries.</small
          >
          <small v-if="$parent.discount.entitled_collection_ids && $parent.discount.entitled_collection_ids.length"
            >Applies only to selected collections.</small
          >
          <small v-if="$parent.discount.specific_products && $parent.discount.specific_products.length"
            >Applies only to selected products.</small
          >
        </v-col>
        <v-col v-if="mini.value == 'quantity_items' && minimum == 'quantity_items'" class="px-0 py-0" cols="6">
          <v-text-field v-model="$parent.discount.prerequisite_quantity_range.greater_than_or_equal_to"></v-text-field>
          <small v-if="$parent.discount.entitled_country_ids && $parent.discount.entitled_country_ids.length"
            >Applies only to selected countries.</small
          >
          <small v-if="$parent.discount.entitled_collection_ids && $parent.discount.entitled_collection_ids.length"
            >Applies only to selected collections.</small
          >
          <small v-if="$parent.discount.specific_products && $parent.discount.specific_products.length"
            >Applies only to selected products.</small
          >
        </v-col>
      </v-radio-group>
    </v-col>
  </v-row>
</template>
<script>
import currency from '@/helpers/currency';
export default {
  data() {
    return {
      currency,
      minimum: '',
      minimums: [
        {
          name: 'None',
          value: 'none',
        },
        {
          name: 'Minimum purchase amount',
          value: 'purchase_amount',
        },
        {
          name: 'Minimum quantity of items',
          value: 'quantity_items',
        },
      ],
    };
  },
  props: {
    checkPrerequisiteValue: {
      type: String,
    },
  },
  created() {
    this.$emit('checkPrerequisite');
    if (this.checkPrerequisiteValue == 'purchase_amount') {
      this.minimum = 'purchase_amount';
    } else if (this.checkPrerequisiteValue == 'quantity_items') {
      this.minimum = 'quantity_items';
    } else {
      this.minimum = 'none';
    }
  },
  methods: {
    onChange() {
      switch (this.minimum) {
        case 'none':
          this.$parent.discount.prerequisite_subtotal_range = {};
          this.$parent.discount.prerequisite_quantity_range = {};
          break;
        case 'purchase_amount':
          this.$parent.discount.prerequisite_quantity_range = {};
          break;
        case 'quantity_items':
          this.$parent.discount.prerequisite_subtotal_range = {};
          break;
        default:
          break;
      }
    },
  },
};
</script>
