<template>
  <v-row class="elevation-form mt-5 px-3 py-3">
    <v-col cols="12">
      <h3>Usage limits</h3>
    </v-col>
    <v-col cols="12">
      <v-checkbox
        hide-details
        label="Limit number of times this discount can be used in total"
        v-model="$parent.usageLimit"
        @change="onChange"
      ></v-checkbox>
      <v-col v-if="$parent.usageLimit" class="px-0 py-0" cols="6">
        <v-text-field v-model="$parent.discount.usage_limit"></v-text-field>
      </v-col>
      <v-checkbox
        class="mt-0"
        v-model="$parent.discount.once_per_customer"
        hide-details
        label="Limit to one use per customer"
      ></v-checkbox>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    usage_limit() {
      return this.$parent.discount.usage_limit > 0 ? true : false;
    },
  },
  methods: {
    onChange() {
      if (!this.$parent.usageLimit) {
        this.$parent.discount.usage_limit = -1;
      } else {
        this.$parent.discount.usage_limit = 0;
      }
    },
  },
};
</script>
